<template>
  <div v-if="!isCookieAgreed" class="coocke-bar-sec">
    <section class="container">
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <div class="cookie-content-wrap">
              <div class="cooki-logo">
                <img :src="require(`@/base/assets/imgs/brand.png`)" alt="" />
              </div>
              <div class="cookie-content">
                <h3 class="semiBold-15">
                  {{ $t("cookiebar_title") }}
                </h3>
                <p class="regular-12">
                  {{ $t("cookiebar_text") }}
                </p>
                <div class="accept-check-box-btn">
                  <div class="accept-checkboxes-wrap">
                    <div class="c-checkbox">
                      <input type="checkbox" name="check01" checked disabled />
                      <label class="regular-12">{{
                        $t("cookiebar_necessary")
                      }}</label>
                    </div>
                    <div class="c-checkbox">
                      <input
                        type="checkbox"
                        v-model="allCookies"
                        name="check02"
                      />
                      <label class="regular-12">{{
                        $("cookiebar_other")
                      }}</label>
                    </div>
                  </div>
                  <button class="c-btn c-btn-primary" @click="onClick">
                    {{ $t("cookiebar_accept") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CookieBarWrap",
  components: {},
 
  data() {
    return {
      allCookies: true,
      isCookieAgreed: this.$cookies.isKey("websiteCookie"),
    };
  },
  methods: {
    onClick() {
      this.isCookieAgreed = true;
      this.$cookies.config("180d");
      this.$cookies.set("websiteCookie", this.allCookies);
      if (this.allCookies == true) {
        this.$gtm.enable(true);
      }
    },
  },
};
</script>

<style>
</style>